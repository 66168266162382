import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationsService, LocationsTreeItem } from '../../api';
import { LibrariesService } from '../../core/libraries.service';
import { SelectedRecipesService } from '../selected-recipes.service';
import { recipesActionType } from './recipes-action.component';

@Injectable({
  providedIn: 'root'
})
export class TreeResolverService implements Resolve<LocationsTreeItem[]> {
  constructor(
    private librariesService: LibrariesService,
    private selectedRecipesService: SelectedRecipesService,
    private locationService: LocationsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<LocationsTreeItem[]> {
    const libraryId = route.parent!.params['libraryId'] as string;
    const unitType = route.queryParams['unitType'];

    if (libraryId) {
      const library = this.librariesService.usedLibraries.find(
        l => l.libraryId === libraryId
      );
      const action = route.data['action'] as recipesActionType;

      let recipeIds: string[] | undefined;

      if (action === 'remove') {
        recipeIds = this.selectedRecipesService.selectedRecipes.map(
          r => r.recipeId
        );
      }

      if (!library) {
        console.error('Cannot find library with id ' + libraryId);
        return EMPTY;
      }

      if (library.companyId) {
        return this.locationService.getLocationsTree(
          recipeIds,
          undefined,
          unitType
        );
      } else if (library.groupId) {
        return this.locationService.getLocationsTree(
          recipeIds,
          library.groupId,
          unitType
        );
      } else if (library.locationId) {
        // @todo: change api to support locationId as parameter?
        return this.locationService
          .getLocationsTree(recipeIds, undefined, unitType)
          .pipe(
            map(tree => tree.filter(l => l.locationId === library.locationId))
          );
      } else {
        return this.locationService.getLocationsTree(
          recipeIds,
          undefined,
          unitType
        );
      }
    } else {
      return this.locationService.getLocationsTree(
        undefined,
        undefined,
        unitType
      );
    }

    return EMPTY;
  }
}
