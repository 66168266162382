import { Injectable } from '@angular/core';
import { RecipeBase } from '../api';

@Injectable({
  providedIn: 'root'
})
export class SelectedRecipesService {
  selectedRecipes: RecipeBase[];

  constructor() {}
}
