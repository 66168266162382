export enum CanvasTool {
  PEN = "Pen",
  ERASER = "Eraser",
  RECTANGLE = "Rectangle",
  CIRCLE = "Circle",
  LINE = "Line",
  ARROW = "Arrow",
  TEXT = "Text",
  UNDO = "Undo",
  REDO = "Redo",
  CLEAR = "Clear",
  NONE = "None"
}

export enum ToolColor {
  green = "#8FC92A",
  blue = "#4968D6",
  red = "#D64123",
  yellow = "#FFD21C",
  white = "#FFFFFF",
  black = "#000000"
  // none = "orange" // For debugging purposes
}

export class CanvasPoint {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  distanceTo(point: CanvasPoint): number {
    const dx = this.x - point.x
    const dy = this.y - point.y
    return Math.sqrt(dx ** 2 + dy ** 2)
  }

  angleTo(point: CanvasPoint): number {
    const dx = this.x - point.x
    const dy = this.y - point.y
    return Math.atan2(dy, dx)
  }

  offsetRTheta(r: number, theta: number): CanvasPoint {
    const offsetX = this.x + r * Math.cos(theta)
    const offsetY = this.y + r * Math.sin(theta)
    return new CanvasPoint(offsetX, offsetY)
  }

  toString() {
    return `${this.x},${this.y}`
  }
}

export interface CanvasRect {
  left: number
  top: number
  width: number
  height: number
}

export interface CanvasRect {
  left: number
  top: number
  width: number
  height: number
}

export interface CanvasLine {
  begin: CanvasPoint
  end: CanvasPoint
  tool: CanvasTool
}

export interface CanvasContextConfig {
  lineWidth: number
  lineCap: string
  strokeStyle: string
  globalCompositeOperation?: string
}
