import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from "@angular/router"
import { EMPTY, Observable } from "rxjs"
import { every } from "rxjs/operators"
import { checkForCookie } from "src/app/core/utils"
import { Location, LocationsService } from "../../api"

@Injectable({
  providedIn: "root"
})
export class LocationsResolverService implements Resolve<Array<Location>> {
  constructor(
    private locationService: LocationsService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Array<Location>> {
    return this.locationService.getLocations()
  }
}
