import { Observable, Observer } from "rxjs"

export class CanvasArray<T> extends Array<T> {
  private observer: Observer<number>
  public observable: Observable<number>

  constructor() {
    super()
    this.observable = new Observable(observer => {
      this.observer = observer
    })
  }

  push(x: T) {
    const i1 = this.length
    const i2 = super.push(x)
    setTimeout(() => {
      delete this[i1]
      if (this.observer) {
        this.observer.next(0)
      }
    }, 800)
    return i2
  }
}
