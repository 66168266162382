import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot
} from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { DialogModalService } from "src/app/core/dialog-modal.service"
import { EditRecipeComponent } from "./edit-recipe.component"

@Injectable({
  providedIn: "root"
})
export class EditRecipeDeactivateGuard
  implements CanDeactivate<EditRecipeComponent> {
  constructor(
    private dialogService: DialogModalService,
    private ts: TranslateService
  ) {}
  canDeactivate(
    component: EditRecipeComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    if (!component.hasUnsavedData()) {
      return true
    }
    // We can't change the redirect functionality of the recipeLibrary page or the overview page,
    // so just check if the url we are navigating to is recipeLibrary or overview,
    // if it is then just allow the navigation and don't display the module
    // since we KNOW that recipeLibrary and overview will redirect right away (its guaranteed) and so the modal will pop up then
    // This is to prevent double pop ups for changes not saved
    if (nextState.url === "/recipeLibrary" || nextState.url === "/overview") {
      return true
    }
    return this.dialogService
      .dangerConfirm({
        title: this.ts.instant("GLOBAL.WARNING"),
        detail: this.ts.instant("RECIPE_LIBRARY.EDITOR.EXIT_CONFIRM"),
        acceptButtonLabel: this.ts.instant("GLOBAL.CONTINUE")
      })
      .close$.pipe(map(closeEv => closeEv.type === "accept"))
  }
}
